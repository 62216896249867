<template>
  <v-container>
    <v-layout row>
      <v-flex xs12>
        <h1 style="margin-top: 80px">Создать новую рассылку</h1>
        <v-form ref="form">
          <v-text-field
                  v-model="text"
                  :counter="max"
                  label="Текст сообщения"
          ></v-text-field>
          <p>Выберите дату и время начала рассылки</p>
          <v-date-picker
                  v-model="picker"
                  label="отправить не ранее"
          ></v-date-picker>
          <v-time-picker
                  v-model="time"
                  format="24hr"
                  landscape
          ></v-time-picker>
          <p>Выберите дату и время окончания рассылки</p>
          <v-date-picker
                  v-model="picker2"
                  label="не отправлять после"
          ></v-date-picker>
          <v-time-picker
                  v-model="time2"
                  format="24hr"
                  landscape
          ></v-time-picker>
          <v-select
                  v-model="code"
                  :items="codes"
                  label="Выберите код получателя"
          ></v-select>
          <v-select
                  v-model="tag"
                  :items="tags"
                  label="Выберите тэг получателя"
          ></v-select>
          <v-btn
                  @click="createNew"
                  block
                  width="50%"
                  elevation="12"
                  large
                  color="primary"
                  dark
          >Создать рассылку
          </v-btn>
          <div class="gap"></div>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
    import axios from "axios";
    import {BASE_API_URL, config} from "@/config";

    export default {
        data() {
            return {
                max: 140,
                text: '',
                time: '00:00',
                time2: '00:00',
                code: 1,
                codes: ['1', '2', '3'],
                tag: 1,
                tags: ['1', '2', '3', '4', '5'],
                picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                picker2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            }
        },
        methods: {
            createNew() {
                const data = {
                    "start_ts": this.picker + ' ' + this.time,
                    "text": this.text,
                    "code": this.code,
                    "tag": this.tag,
                    "stop_ts": this.picker2 + ' ' + this.time2,
                }
                console.log(data)
                axios
                    .post(`${BASE_API_URL}/api/sendings`, data, {
                        headers: {
                            'Authorization': 'Bearer ' + config.token,
                            'Content-Type': 'application/json',
                            'Accept': 'Application/json',
                        }
                    })
                    .then((response) => {
                        console.log(response.data, response.status)
                        alert('Расссылка создана!')
                    })
                    .catch((error) => {
                        console.log(error)
                        alert('Ошибка при создании рассылки', error)
                    })
            },
        },
    }
</script>

<style scoped>
  h1 {
    margin-top: 80px;
    margin-left: 40px;
  }
  p {
    margin-left: 40px;
  }
  .gap {
    margin: 30px;
  }
</style>
