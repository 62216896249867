<template>
  <v-container>
    <v-layout row>
      <v-flex xs12>
        <h1 style="margin-top: 80px;margin-left: 30px;">Административная панель</h1>
        <div>
          <p>UI интерфейс к <a href="https://www.craft.do/s/n6OVYFVUpq0o6L">тестовому заданию</a></p>
          <p v-if="!$auth.isAuthenticated">Для создания новый рассылки необходимо авторизоваться в сервисе Auth0.com по ссылке "ВХОД"</p>
          <p><a href="https://test-job.xland.ru/docs">Документация API</a> в формате Swagger UI
            (может быть недоступна при выключенном бэкенде)</p>
          <p><a href="https://gitlab.com/boangri/test-job-ui">Исходный код UI</a></p>
          <p><a href="https://gitlab.com/boangri/fbrq-test-job">Исходный код API</a></p>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
    export default {
        data () {
            return {}
        },
        methods: {
            // Log the user in
            login() {
                this.$auth.loginWithRedirect();
            },
            // Log the user out
            logout() {
                this.$auth.logout({
                    returnTo: window.location.origin
                });
            }
        }
    }
</script>

<style scoped>
  h1 {
    margin-top: 80px;
    margin-left: 40px;
  }
  p {
    margin-left: 40px;
  }
</style>
