<template>
  <v-app>

    <v-app-bar dark
               fixed
               color="primary"
               elevation="10"
    >
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        class="hidden-md-and-up"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>Сервис уведомлений</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          v-for="link of links"
          :key="link.title"
          :to="link.url"
          color="primary"
        >
          <v-icon left>mdi-{{ link.icon }}</v-icon>
          {{ link.title }}
        </v-btn>
        <v-btn v-if="!$auth.isAuthenticated" @click="login" color="primary">
          <v-icon left>mdi-login</v-icon>
          Вход
        </v-btn>
        <v-btn v-if="$auth.isAuthenticated" @click="new_sending" color="primary">
          <v-icon left>mdi-bell-plus</v-icon>
          Новая рассылка
        </v-btn>
        <v-btn v-if="$auth.isAuthenticated" @click="profile" color="primary">
          <v-icon left>mdi-account</v-icon>
          Профиль
        </v-btn>
        <v-btn v-if="$auth.isAuthenticated" @click="logout" color="primary">
          <v-icon left>mdi-logout</v-icon>
          Выход
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer app temporary v-model="drawer">
      <v-list>
        <v-list-item
                v-if="!$auth.isAuthenticated" @click="login"
        >
          <v-icon>mdi-login</v-icon>
          <v-list-item-title text="Вход">Вход</v-list-item-title>
        </v-list-item>
        <v-list-item
                v-if="$auth.isAuthenticated" @click="new_sending"
        >
          <v-icon>mdi-bell-plus</v-icon>
          <v-list-item-title text="Новая рассылка">Новая рвссылка</v-list-item-title>
        </v-list-item>
        <v-list-item
                v-if="$auth.isAuthenticated" @click="profile"
        >
          <v-icon>mdi-account</v-icon>
          <v-list-item-title text="Профиль">Профиль</v-list-item-title>
        </v-list-item>
        <v-list-item
                v-if="$auth.isAuthenticated" @click="logout"
        >
          <v-icon>mdi-logout</v-icon>
          <v-list-item-title text="Выход">Выход</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="link of links"
          :key="link.title"
          :to="link.url"
        >
          <v-icon>mdi-{{ link.icon }}</v-icon>
          <v-list-item-title v-text="link.title"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>
    <v-footer
            v-bind="localAttrs"
            :padless="padless"
            color="primary"
            dark
    >
      <v-card
              flat
              tile
              width="100%"
              class="primary text-center"
      >
        <v-card-text>
          <v-btn
                  v-for="icon in icons"
                  :key="icon.icon"
                  class="mx-4"
                  icon
          >
            <v-icon size="24px">
              mdi-{{ icon.icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          &copy;{{ new Date().getFullYear() }} — <strong>Борис Грибовский</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
        drawer: false,
        padless: false,
        localAttrs: [],
        links: [
            {title: 'Главная', icon: 'home', url: '/'},
        ],
        icons: [
            {link: 'https://github.com/boangri', icon: 'github'},
            {link: '', icon: 'twitter'},
            {link: '', icon: 'facebook'},
            {link: '', icon: 'instagram'},
        ],
    }
  },
  methods: {
      login() {
          this.$auth.loginWithRedirect();
      },
      logout() {
          this.$auth.logout({
              returnTo: window.location.origin
          });
      },
      new_sending() {
          this.$router.push('/new')
      },
      profile() {
          this.$router.push('/profile')
      },
  }
}
</script>
